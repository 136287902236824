export default function confirmPorts (ports) {
  if (!ports.confirm) return

  ports.confirm.subscribe(message => {
    const response = window.confirm(message)
    if (!ports.confirmed) return

    ports.confirmed.send(response)
  })
}
